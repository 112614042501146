<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">

              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
<!--                  <i class="fas fa-arrow-left pr-4 cursor-pointer" @click="back"></i>-->
                  Additional assessment marks entry for {{
                    course && course.title ? course.title :
                        'Subject'
                  }}</h4>

              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">
                <router-link
                    :to="{name:'results-view-all-courses-students',params:{slug:exam.slug, 'courseId':course.id}}"
                    class="btn font-weight-bold ml-2 text-black">
                  Go to Result entry page
                </router-link>
              </div>
            </div>

            <div class="row mb-5" style="background-color:#e7f0f3">
              <div class="col-md-6">
                <b>Program :</b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
                <b>Semester/year :</b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
                <b>Course :</b> {{ course && course.title ? course.title : null }} <br>
                <b>Assessment Title :</b> {{ additionalAssessment ? additionalAssessment.title : null }} <br>
              </div>

              <div class="col-md-6">
                <b>Exam :</b> {{ exam ? exam.title : null }} <br>
                <b>Exam Type:</b> {{ exam && exam.type ? exam.type.toUpperCase() : null }} <br>
                <b>Total mark:</b> {{ additionalAssessment.mark }} <br>
                <b>Pass mark:</b> {{ additionalAssessment.pass_mark }} <br>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <v-btn :loadng="is_loading" @click="save()"
                     class="btn btn-primary ml-2 text-white">
                Save
              </v-btn>
            </div>
            <div class="col-12">
              <v-card
                  flat
                  :loading="is_loading">


                <div class="">
                  <table class="table table-stripe">
                    <thead>
                    <th>Student</th>
                    <th
                    style="max-width: 150px">Mark obtained</th>
                    </thead>
                    <tbody v-cloak>
                    <tr v-for="(student, index) of result_students" :key="index">
                      <td>
                        <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                          <b>  {{ student.full_name }} </b><br/>
                        </a>

                          <b> CRN No.: </b>{{ student.crn_no }}<br/>
                          <b v-if="student.midas_id">External ID:</b> {{ student.midas_id }} <br v-if="student.midas_id">

                        <span class="text-secondary" v-if="student.symbol_no">
                         <b> Symbol No.:</b>  {{student.symbol_no  ? student.symbol_no : 'NA'}}<br/>
                        </span>

                        <span class="text-secondary" v-if="student.setting && student.setting.academic_year">
                          <b> Batch:</b>  {{student.setting.academic_year!=null  ? student.setting.academic_year : 'NA'}}<br/>
                        </span>
                      </td>
                      <td
                      style="max-width: 150px">
                        <v-text-field
                          v-model="student.mark"
                          type="number"
                          label="Mark"
                          style="max-width: 100px"
                          placeholder="Mark"
                          outlined
                          dense
                        ></v-text-field>
                      </td>


                    </tr>
                    </tbody>
                  </table>
                </div>

              </v-card>

            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import CoursesService from "@/core/services/courses/CoursesService";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
import StudentCourseResultService from "@/core/services/user/student/result/StudentCourseResultService";
import AdditionalAssessment from "@/core/services/additionalAssessment/AdditionalAssessmentService";


const examService = new ExamService();
const academicClassService = new AcademicClassService();
const courseService = new CoursesService();
const resultService = new StudentResultService();
const courseResultService = new StudentCourseResultService();
const additionalAssessment = new AdditionalAssessment();
export default {
  name: "academic-class",
  components: {},
  data() {
    return {
      is_loading: false,
      result_students: [],
      result_asessments: [],
      final_data: [],
      final: [],
      exam: {},
      course: {},
      selectedStudents: [],
      additionalAssessment: null,
      academic_classes: [],
      exams: [],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      headers: [
        {
          text: 'Student',
          align: 'start',
          sortable: false,
          value: 'name',
        },

      ],
    };
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    assessmentId() {
      return this.$route.params.assessmentId;
    },
    slug() {
      return this.$route.params.slug;
    },


  },
  watch: {},
  created() {
    this.getExam();
    this.getCourse();
  },
  mounted() {
    // this.getAcademicClasses();

  },
  methods: {

    back() {
      this.$router.go(-1);
    },

    getExam() {
      // this.$bus.emit('toggleLoader');
      examService.getBySlug(this.slug).then(response => {
        this.exam = response.data.exams;
        this.getAdditionalAssessments();
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        // this.$bus.emit('toggleLoader');
      });
    }, getCourse() {
      courseService.show(this.courseId).then(response => {
        this.course = response.data.courses;
      });
    },
    getAdditionalAssessments() {
      additionalAssessment.show(this.courseId, this.assessmentId).then(response => {
        this.additionalAssessment = response.data.additionalAssessment;
        this.getResultAssignedStudents();

      });
    },
    getAssignedStudents() {
      // this.$bus.emit('toggleLoader');
      this.is_loading = true;
      examService.getAssignedStudentsByCourse(this.exam.id, this.courseId).then(response => {

        this.selectedStudents = response.data;
        this.selectedStudents.forEach((element) => {
          console.log(element)
          let newData = {
            student_id: element.student_id,
            full_name: element.full_name,
            course_id: parseInt(this.courseId),
            assessment_id: parseInt(this.assessmentId),
            exam_id: this.exam.id,
            academic_year_id: this.exam.academic_year_id,
            class_id: this.exam.grade_id,
            mark: null,
            program_id: this.exam.program_id,
          }
          this.result_students.push(newData)
        })
        this.getResultAssignedStudents();
        this.is_loading = false;

      });


    },
    getResultAssignedStudents() {
      // this.$bus.emit('toggleLoader');
      this.is_loading = true;
      additionalAssessment.getAssignedStudents(this.exam.grade_id, this.assessmentId,{'exam_id':this.exam.id || null}).then(response => {
        this.selectedStudents = response.data.students;
        this.selectedStudents.forEach((element) => {
          let newData = {
            student_id: element.student_id,
            full_name: element.full_name,
            course_id: parseInt(this.courseId),
            assessment_id: parseInt(this.assessmentId),
            exam_id: this.exam.id,
            academic_year_id: this.exam.academic_year_id,
            class_id: this.exam.grade_id,
            mark: element.mark,
            midas_id: element.midas_id,
            crn_no: element.crn_no,
            program_id: this.exam.program_id,
          }
          this.result_students.push(newData)
        })
        this.is_loading = false;


        this.is_loading = false;

      });


    },


    openDialog(exam) {
      this.$refs["exam"].showModal(exam);
    },
    manageResult(exam) {
      this.$refs["manageResult"].showModal(exam);
    },
    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            academicClassService.delete(id).then(response => {
              this.$snotify.success("Record Deleted");
              this.getAcademicClasses();
            });
          }
        }
      });
    }, saveStudentResultByCourse() {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.is_loading = true;
            resultService.saveStudentResultByCourse(this.exam.id, this.result_students).then(response => {
              this.$snotify.success("Record saved");
              this.is_loading = false;
              this.result_students = [];
              this.getAssignedStudents();
            }).catch(error => {
              // console.log(error)
            }).finally(() => {
              this.is_loading = false;
            })
          }
        }
      });
    },
    save() {
      this.$confirm({
        message: `Are you sure you want save?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {

            this.is_loading = true;
            additionalAssessment.syncStudents(this.additionalAssessment.id, this.result_students).then(response => {
              this.$snotify.success("Record saved");
              this.is_loading = false;
              this.result_students = [];
              this.getResultAssignedStudents();
            }).catch(error => {
              // console.log(error)
            }).finally(() => {
              this.is_loading = false;
            })
          }
        }
      });
    }
  }
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
